<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nueva licitación</h1>
        <hr class="">
      </v-col>
      <v-form ref="form" @submit.prevent="submitForm">
        <v-row>
          <v-col cols="12" lg="6" class="mt-10">
            <v-text-field
                v-model="data.name"
                label="Nombre"
                placeholder="Nombre"
                :rules="[rules.required]"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="mt-10">
            <v-text-field
                v-model="data.process"
                label="Procedimiento de contratación"
                :rules="[rules.required]"
                placeholder="Procedimiento de contratación:"
                outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" lg="6">
            <v-text-field
                label="Código ID"
                v-model="data.clave"
                placeholder="Código ID"
                :rules="[rules.required]"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
                @keypress="isNumber($event)"
                label="Suficiencia presupuestal"
                v-model="data.budgetarySufficiency"
                :rules="[rules.required]"
                placeholder="Suficiencia presupuestal"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
                label="Anticipo"
                v-model="data.advance"
                placeholder="Anticipo"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
                label="Área requerimiento"
                v-model="data.area"
                placeholder="Área requerimiento"
                outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" lg="6" class="mt-4  ">
            <div class=" d-flex justify-center" style="width: 100%">
              <v-radio-group v-model="data.tipo" row class="justify-space-between">
                <span class="mr-4">Nuevo</span>
                <v-radio value="nuevo"></v-radio>
                <span class="mr-4">Bis</span>
                <v-radio value="bis"></v-radio>
                <span class="mr-4">Ter</span>
                <v-radio value="ter"></v-radio>
              </v-radio-group>

            </div>

          </v-col>
          <v-col cols="12" lg="6" class="mt-4  ">
            <v-checkbox label="Fianza" v-model="data.fianza"></v-checkbox>
          </v-col>
          <v-col  lg="12" class="mt-4  " v-if="data.tipo !== 'nuevo'">
            <v-autocomplete
                :items="tendersData"
                label="Seleccione el tipo de licitacion anterior"
                v-model="data.parent_id"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" lg="6" class="mt-4  ">
            <div class="bg-radio-button d-flex justify-center" style="width: 100%">
              <v-radio-group v-model="data.type" row class="justify-space-between">
                <span class="mr-4">Nacional</span>
                <v-radio value="NACIONAL"></v-radio>
                <span class="mr-4">Local</span>
                <v-radio value="LOCAL"></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col cols="12" lg="6" class="mt-4  ">
            <div class="bg-radio-button d-flex justify-center" style="width: 100%">
              <v-radio-group v-model="data.committee" row class="justify-space-between">
                <span class="mr-4">Comite</span>
                <v-radio value="true"></v-radio>
                <span class="mr-4">Sin comite</span>
                <v-radio value="false"></v-radio>
              </v-radio-group>
            </div>
          </v-col>


          <v-col cols="12">
            <v-btn type="submit" color="primary" style="width: 200px">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../../components/Overlay";

export default {
  name: "Tenders",
  data() {
    return {
      tendersData: [],
      overlay: false,
      data: {
        name: "",
        area: "",
        clave: "",
        parent_id: "",
        fianza: false,
        tipo: "nuevo",
        type: 'LOCAL',
        committee: 'false',
        budgetarySufficiency: "",
        advance: "",
      },
      rules: {
        required: value => !!value || 'Este campo es requerido.',
      },
    };
  },
  watch: {
    'data.budgetarySufficiency'(newValue) {
      if (newValue !== null) {
        this.data.budgetarySufficiency = parseFloat(newValue);
      }
    },
  },
  components: {
    Overlay
  },
  async created() {
    const dataResponse = await this.findAllTenderCatalog();
    this.tendersData = dataResponse.tenders.map(res => {
      return {text: res.nombre+ ' (' + res.claveLicitacion + ')', value: res.id}
    });
  },
  methods: {
    ...mapActions('tenders', ['createTenders', 'findAllTenderCatalog']),
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        if (this.data.tipo === 'nuevo'){
          this.data.parent_id = null;
        }
        if (this.data.tipo !== 'nuevo' ){
          this.sweetAlertError('Debe seleccionar una licitacion para continuar');
          return
        }
        const response = await this.createTenders(this.data);
        if (!response.error) {
          this.overlay = true;
          this.sweetAlertSuccess('Se creo correctamente')
          await this.$router.push('/licitaciones')
        } else {
          this.overlay = false;
          this.sweetAlertError(response.error)
        }
      }
    },
  },
}
</script>

<style scoped>
.v-list-item__title{
  color: black !important;
}
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: black !important;
  text-align: left;
}
.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
